import { useEffect } from "react";
import {
    BackgroundType,
    CSSVariableName,
    DarkMode,
    Shape,
    UITheme,
} from "types";
import { handleSetThemeColors } from "utilities/theme";
import { useSelector } from "react-redux";
import { selectSystemMode } from "store/systemModeSlice";
import { core_palette } from "utilities/palette";

const buttonRadius = {
    square: 1,
    soft: 12,
    round: 9999,
};
const blockRadius = {
    square: 1,
    soft: 12,
    round: 24,
};

function generateUITheme(mode: DarkMode) {
    return {
        dark_mode: mode === DarkMode.dark,
        mode,
        background_color: core_palette.inverse[mode],
        border_color: core_palette.quaternary[mode],
        primary: core_palette.primary[mode],
        secondary: core_palette.secondary[mode],
        tertiary: core_palette.tertiary[mode],
        quaternary: core_palette.quaternary[mode],
        quinary: core_palette.quinary[mode],
        inverse: core_palette.inverse[mode],
        critical: core_palette.critical[mode],
        success: core_palette.success[mode],
        button_radius: buttonRadius.round,
        block_radius: blockRadius.round,
        background_type: BackgroundType.minimal,
        shape: Shape.round,
        background_image_url: "",
    };
}

export function useUITheme(allowDarkMode?: boolean): UITheme {
    const darkMode = useSelector(selectSystemMode);
    const systemMode = darkMode ? DarkMode.dark : DarkMode.light;
    // const mode = allowDarkMode ? systemMode : DarkMode.light;
    const mode = DarkMode.light; // theme mode won't change based on system mode

    const theme = generateUITheme(mode);

    useEffect(() => {
        handleSetThemeColors(CSSVariableName.uiPrimary, theme.primary);
        handleSetThemeColors(CSSVariableName.uiSecondary, theme.secondary);
        handleSetThemeColors(CSSVariableName.uiTertiary, theme.tertiary);
        handleSetThemeColors(CSSVariableName.uiQuaternary, theme.quaternary);
        handleSetThemeColors(CSSVariableName.uiQuinary, theme.quinary);
        handleSetThemeColors(CSSVariableName.uiInverse, theme.inverse);
        handleSetThemeColors(CSSVariableName.uiCritical, theme.critical);
        handleSetThemeColors(CSSVariableName.uiSuccess, theme.success);
        handleSetThemeColors(
            CSSVariableName.uiBackground,
            theme.background_color,
        );
        handleSetThemeColors(CSSVariableName.uiBorder, theme.border_color);
    }, [theme]);

    return theme;
}
