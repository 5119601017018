import { clsx } from "clsx";
import type { CSSProperties } from "react";
import { useUITheme } from "hooks/useUITheme";
import Link from "next/link";
import { ButtonMode, DarkMode, UITheme } from "types";
import { core_palette } from "utilities/palette";
import { Icon, IconsMap } from "./Icon";

type ButtonIconProps = {
    icon?: Icon;
    className: string;
    iconSize: number;
    color: string;
};
function ButtonIcon({ icon, className, iconSize, color }: ButtonIconProps) {
    if (!icon) {
        return null;
    }
    if (icon === "no_icon") {
        return <></>;
    }
    const IconFromMap = IconsMap[icon];
    return <IconFromMap color={color} className={className} size={iconSize} />;
}

type FetchingIconProps = {
    color: string;
    fetching: boolean;
};

function FetchingIcon({ color, fetching }: FetchingIconProps) {
    if (!fetching) {
        return null;
    }
    return (
        <IconsMap.spinner
            className="animate-spin theme-inverse-text"
            color={color}
        />
    );
}

type ButtonTitleProps = {
    title: string;
    defaultClassName: string;
    textClassName: string;
    color: string;
};

function ButtonTitle({
    title,
    defaultClassName,
    textClassName,
    color,
}: ButtonTitleProps) {
    if (!title) {
        return null;
    }
    return (
        // wrapping the default class name here is needed because tailwind css conflict classnames will not follow css cascading order
        <span className={defaultClassName}>
            <span className={`${textClassName}`} style={{ color }}>
                {title}
            </span>
        </span>
    );
}

function getBgColor({
    colorOverride,
    theme,
    mode,
}: {
    colorOverride: string;
    theme: UITheme;
    mode: ButtonMode;
}) {
    if (colorOverride) {
        return colorOverride;
    }

    switch (mode) {
        case ButtonMode.solid:
            return core_palette.primary[theme.mode];
        case ButtonMode.glass:
            return theme.quinary;
        case ButtonMode.outline:
            return theme.background_color;
        case ButtonMode.ghost:
            return theme.background_color;
        default:
            return theme.primary;
    }
}

function getTextColor({
    colorOverride,
    theme,
    mode,
}: {
    colorOverride: string;
    theme: UITheme;
    mode: ButtonMode;
}) {
    if (colorOverride) {
        return colorOverride;
    }

    switch (mode) {
        case ButtonMode.solid:
            return core_palette.inverse[theme.mode];
        case ButtonMode.glass:
            return theme.primary;
        case ButtonMode.outline:
            return theme.primary;
        case ButtonMode.ghost:
            return theme.primary;
        default:
            return theme.inverse;
    }
}

function getHoverEffect({
    darkMode,
    mode,
}: {
    darkMode: DarkMode;
    mode: ButtonMode;
}) {
    switch (darkMode) {
        case DarkMode.dark: {
            // lighten in dark mode
            if (mode === ButtonMode.solid || mode === ButtonMode.default) {
                return "hover:!opacity-90 hover:brightness-90";
            }
            if (
                mode === ButtonMode.ghost ||
                mode === ButtonMode.glass ||
                mode === ButtonMode.outline
            ) {
                return "bg-hover:!bg-opacity-30 hover:!bg-gray11/20";
            }
        }
        case DarkMode.light: {
            // darken in light mode
            if (mode === ButtonMode.solid || mode === ButtonMode.default) {
                return "hover:!opacity-90 hover:!brightness-90";
            }
            if (mode === ButtonMode.ghost || mode === ButtonMode.glass) {
                return "hover:!bg-gray10/10";
            }
            if (mode === ButtonMode.outline) {
                return "hover:!brightness-95";
            }
        }
    }
}

type ButtonThemeProps = {
    theme: UITheme;
    mode: ButtonMode;
};

function getBorderColor({ theme, mode }: ButtonThemeProps) {
    switch (mode) {
        case ButtonMode.outline:
            return theme.quaternary;
        case ButtonMode.glass:
            return theme.quaternary;
        default:
            return "transparent";
    }
}

type Props = {
    title?: string;
    icon?: Icon;
    backgroundColor?: string;
    color?: string;
    iconSize?: number;
    iconPosition?: "left" | "right";
    onClick?: (e: any) => void;
    textClassName?: string;
    iconClassName?: string;
    id?: string;
    mode?: ButtonMode;
    style?: React.CSSProperties;
    fetching?: boolean;
    disabled?: boolean;
    htmlComponent?: "button" | "a";
    href?: string;
    // keep this?
    isWide?: boolean;
    animateScale?: boolean;
    newTab?: boolean;
    hasShadow?: boolean;
    padding?: string;
    grow?: boolean;
    allowDarkMode?: boolean;
    borderRadius?: string;
    className?: string;
};

type ButtonOrAnchorProps = Props & {
    children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement>;

function ButtonOrAnchor(props: ButtonOrAnchorProps) {
    const { htmlComponent, children, href = "", newTab } = props;
    const passProps = {
        ...props,
    };
    delete passProps.htmlComponent;
    delete passProps.newTab;
    const newTabProps = newTab
        ? {
              target: "_blank",
              rel: "noopener noreferrer",
          }
        : {};
    if (htmlComponent === "a" || href) {
        return (
            <Link {...passProps} href={href} {...newTabProps}>
                {children}
            </Link>
        );
    }
    return <button {...passProps}>{children}</button>;
}

export default function UIButton(props: Props) {
    const {
        title,
        icon,
        iconPosition = "left",
        backgroundColor = "",
        color,
        iconClassName = "",
        iconSize = 24,
        onClick = () => {},
        textClassName = "",
        id = "",
        style = {},
        fetching = false,
        mode = ButtonMode.solid,
        disabled = false,
        // is wide stretches the width of button with a max width of 300px
        isWide = false,
        htmlComponent = "button",
        href,
        animateScale = false,
        newTab = false,
        hasShadow = false,
        padding = "p-3",
        grow = true,
        allowDarkMode = false,
        borderRadius,
        className,
    } = props;

    const _theme = useUITheme(allowDarkMode);
    const textColor = getTextColor({
        colorOverride: color || "",
        theme: _theme,
        mode: mode,
    });

    const hoverEffect = getHoverEffect({
        darkMode: _theme.mode,
        mode: mode,
    });

    const boxShadow = hasShadow
        ? "0px 4px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04), 0px 1px 4px rgba(0, 0, 0, 0.08)"
        : "unset";

    const _onClick = (e: any) => {
        if (fetching) {
            return;
        }
        onClick(e);
    };

    const classNames = clsx(
        // force hardware acceleration on gpu since we're using filters/backdrop-filters
        "transform-gpu",
        `${padding} ${hoverEffect} flex-row flex justify-center items-center cursor-pointer transition-background duration-300 gap-2 border border-solid`,
        {
            "flex-grow": grow,
            "max-w-[300px] w-[50vw] py-4": isWide,
            "hover:scale-[.98]": animateScale,
        },
        className,
    );

    const buttonStyle: CSSProperties = {
        borderRadius: borderRadius ? borderRadius : _theme.button_radius,
        backgroundColor: getBgColor({
            colorOverride: backgroundColor,
            theme: _theme,
            mode: mode,
        }),
        borderColor: getBorderColor({
            theme: _theme,
            mode: mode,
        }),
        opacity: disabled ? 0.5 : 1,
        // box sizing border box required for padding to not add to width
        boxSizing: "border-box",
        boxShadow,
        ...style,
    };

    return (
        <ButtonOrAnchor
            id={id}
            className={classNames}
            style={buttonStyle}
            onClick={_onClick}
            disabled={disabled}
            htmlComponent={htmlComponent}
            href={href}
            newTab={newTab}
        >
            <FetchingIcon color={_theme.primary} fetching={fetching} />
            {iconPosition === "left" ? (
                <ButtonIcon
                    color={textColor}
                    icon={icon}
                    className={iconClassName}
                    iconSize={iconSize}
                />
            ) : null}
            <ButtonTitle
                title={title || ""}
                defaultClassName="text-sm font-semibold"
                color={textColor}
                textClassName={textClassName}
            />
            {iconPosition === "right" ? (
                <ButtonIcon
                    color={textColor}
                    icon={icon}
                    className={iconClassName}
                    iconSize={iconSize}
                />
            ) : null}
        </ButtonOrAnchor>
    );
}
