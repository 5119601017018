import { clsx } from "clsx";
import type { CSSProperties } from "react";
import { useTheme } from "hooks/useTheme";
import Link from "next/link";
import { BackgroundType, ButtonMode, Properties, Theme } from "types";
import { Preset, checkIsDarkColor } from "utilities/theme";
import { Icon, IconsMap } from "./Icon";

type ButtonIconProps = {
    icon?: Icon;
    className: string;
    iconSize: number;
    color: string;
};
function ButtonIcon({ icon, className, iconSize, color }: ButtonIconProps) {
    if (!icon) {
        return null;
    }
    if (icon === "no_icon") {
        return <></>;
    }
    const IconFromMap = IconsMap[icon];
    return <IconFromMap color={color} className={className} size={iconSize} />;
}

type FetchingIconProps = {
    color: string;
    fetching: boolean;
};

function FetchingIcon({ color, fetching }: FetchingIconProps) {
    if (!fetching) {
        return null;
    }
    return (
        <IconsMap.spinner
            className="animate-spin theme-inverse-text"
            color={color}
        />
    );
}

type ButtonTitleProps = {
    title: string;
    defaultClassName: string;
    textClassName: string;
    color: string;
};

function ButtonTitle({
    title,
    defaultClassName,
    textClassName,
    color,
}: ButtonTitleProps) {
    if (!title) {
        return null;
    }
    return (
        // wrapping the default class name here is needed because tailwind css conflict classnames will not follow css cascading order
        <span className={defaultClassName}>
            <span className={`${textClassName}`} style={{ color }}>
                {title}
            </span>
        </span>
    );
}

function getHoverEffect(mode: ButtonMode, theme: Theme) {
    switch (mode) {
        case ButtonMode.solid:
            return "hover:brightness-90";
        case ButtonMode.ghost:
        case ButtonMode.outline:
            if (
                theme.background_preset_name === Preset.Dark ||
                checkIsDarkColor(theme.background_color)
            ) {
                // background color close to black (or when preset is dark) and the brightness doesn't work
                return "hover:bg-gray11/20";
            } else {
                return "hover:backdrop-brightness-110";
            }
        case ButtonMode.glass:
            return "hover:brightness-110";
    }
}

type ButtonThemeProps = {
    theme: Theme;
    mode: ButtonMode;
};

function getBorderColor({ theme, mode }: ButtonThemeProps) {
    switch (mode) {
        case ButtonMode.outline:
            return theme.quaternary;
        case ButtonMode.glass:
            return theme.quaternary;
        default:
            return "transparent";
    }
}

function getButtonMode({ mode, theme }: ButtonThemeProps) {
    if (mode === ButtonMode.default) {
        switch (theme.background_type) {
            case BackgroundType.minimal:
                return ButtonMode.outline;
            case BackgroundType.dynamic:
                return ButtonMode.glass;
            case BackgroundType.image:
                return ButtonMode.glass;
            default:
                return ButtonMode.outline;
        }
    }
    return mode;
}

type ButtonClassNameProps = {
    mode: ButtonMode;
    theme: Theme;
    styleOverride: boolean;
};

const getButtonClassName = ({
    mode,
    theme,
    styleOverride,
}: ButtonClassNameProps) => {
    let btnBackgroundClassName = "";
    let btnTextClassName = "";
    let btnBorderClassName = "border border-transparent";

    if (styleOverride) {
        return {
            btnBackgroundClassName,
            btnTextClassName,
            btnBorderClassName,
        };
    }

    switch (mode) {
        case ButtonMode.ghost:
            btnTextClassName = "theme-primary-text";
            btnBorderClassName = "border border-transparent";
            break;
        case ButtonMode.glass:
            btnBackgroundClassName = "theme-tertiary-background";
            btnTextClassName = "theme-primary-text";
            btnBorderClassName = "border border-transparent";
            break;
        case ButtonMode.outline:
            btnTextClassName = "theme-primary-text";
            btnBorderClassName = "theme-primary-border";
            break;
        case ButtonMode.solid:
            btnBackgroundClassName = "theme-primary-background";
            btnTextClassName = "theme-inverse-text";
            btnBorderClassName = "border border-transparent";
            break;
        case ButtonMode.default:
            if (theme.background_type === BackgroundType.minimal) {
                btnTextClassName = "theme-primary-text";
                btnBorderClassName = "theme-primary-border";
            } else {
                btnBackgroundClassName = "theme-tertiary-background";
                btnTextClassName = "theme-primary-text";
                btnBorderClassName = "border border-transparent";
            }
            break;
        default:
            btnBackgroundClassName;
            btnTextClassName = "theme-primary-text";
            btnBorderClassName = "theme-primary-border";
    }

    return {
        btnBackgroundClassName,
        btnTextClassName,
        btnBorderClassName,
    };
};

type Props = {
    title?: string;
    icon?: Icon;
    backgroundColor?: string;
    color?: string;
    iconSize?: number;
    iconPosition?: "left" | "right";
    onClick?: (e: any) => void;
    className?: string;
    textClassName?: string;
    id?: string;
    mode?: ButtonMode;
    style?: React.CSSProperties;
    fetching?: boolean;
    disabled?: boolean;
    htmlComponent?: "button" | "a";
    href?: string;
    // keep this?
    useNewSettings?: boolean;
    isWide?: boolean;
    animateScale?: boolean;
    newTab?: boolean;
    hasShadow?: boolean;
    padding?: string;
    grow?: boolean;
    borderRadius?: string;
    noHoverEffect?: boolean;
};

type ButtonOrAnchorProps = Props & {
    children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement>;

function ButtonOrAnchor(props: ButtonOrAnchorProps) {
    const { htmlComponent, children, href = "", newTab } = props;
    const passProps = {
        ...props,
    };
    delete passProps.htmlComponent;
    delete passProps.newTab;
    const newTabProps = newTab
        ? {
              target: "_blank",
              rel: "noopener noreferrer",
          }
        : {};
    if (htmlComponent === "a" || href) {
        return (
            <Link {...passProps} href={href} {...newTabProps}>
                {children}
            </Link>
        );
    }
    return <button {...passProps}>{children}</button>;
}

export default function Button(props: Props) {
    const {
        title,
        icon,
        iconPosition = "left",
        backgroundColor = "",
        color,
        iconSize = 24,
        onClick = () => {},
        className = "",
        textClassName = "",
        id = "",
        style = {},
        fetching = false,
        mode = ButtonMode.solid,
        disabled = false,
        // is wide stretches the width of button with a max width of 300px
        isWide = false,
        htmlComponent = "button",
        href,
        animateScale = false,
        newTab = false,
        hasShadow = false,
        padding = "p-3",
        grow = true,
        borderRadius,
        noHoverEffect,
    } = props;

    const theme = useTheme();
    const _mode = getButtonMode({ mode, theme: theme });
    const styleOverride = !!color || !!backgroundColor;
    const textColor = color || "";
    const hoverEffect = noHoverEffect ? "" : getHoverEffect(_mode, theme);

    // brightness does not work on rgba background, which subtle buttons use
    // instead replace with opacity

    const boxShadow = hasShadow
        ? "0px 4px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04), 0px 1px 4px rgba(0, 0, 0, 0.08)"
        : "unset";

    const _onClick = (e: any) => {
        if (fetching) {
            return;
        }
        onClick(e);
    };

    const { btnBackgroundClassName, btnTextClassName, btnBorderClassName } =
        getButtonClassName({
            mode,
            theme: theme,
            styleOverride,
        });

    const classNames = clsx(
        // force harware acceleration on gpu since we're using filters/backdrop-filters
        "transform-gpu",
        `${padding} ${hoverEffect} lex-row flex justify-center items-center cursor-pointer transition-all duration-200 ease-in-out gap-2 border border-solid ${btnBackgroundClassName} ${btnBorderClassName}`,
        {
            "flex-grow": grow,
            "max-w-[300px] w-[50vw] py-4": isWide,
            "hover:scale-[.98]": animateScale,
            "border-transparent": !styleOverride,
        },
        className,
    );

    const buttonStyle: CSSProperties = {
        borderRadius: borderRadius ? borderRadius : theme.button_radius,
        // if button has override style, we add these style to override the classNames
        ...(styleOverride && {
            backgroundColor: backgroundColor,
            borderColor: getBorderColor({
                theme: theme,
                mode: _mode,
            }),
        }),
        opacity: disabled ? 0.5 : 1,
        // box sizing border box required for padding to not add to width
        boxSizing: "border-box",
        boxShadow,
        ...style,
    };

    return (
        <ButtonOrAnchor
            id={id}
            className={classNames}
            style={buttonStyle}
            onClick={_onClick}
            disabled={disabled}
            htmlComponent={htmlComponent}
            href={href}
            newTab={newTab}
        >
            <FetchingIcon color={theme.inverse} fetching={fetching} />
            {iconPosition === "left" ? (
                <ButtonIcon
                    color={textColor}
                    icon={icon}
                    className={btnTextClassName}
                    iconSize={iconSize}
                />
            ) : null}
            <ButtonTitle
                title={title || ""}
                defaultClassName="text-sm font-semibold"
                color={textColor}
                textClassName={`${textClassName} ${btnTextClassName}`}
            />
            {iconPosition === "right" ? (
                <ButtonIcon
                    color={textColor}
                    icon={icon}
                    className={btnTextClassName}
                    iconSize={iconSize}
                />
            ) : null}
        </ButtonOrAnchor>
    );
}
