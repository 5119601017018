import chroma from "chroma-js";
import { useEffect } from "react";
import {
    BackgroundType,
    CSSVariableName,
    DarkMode,
    Settings,
    Theme,
} from "types";
import {
    DEFAULT_BACKGROUND,
    DEFAULT_BORDER,
    DEFAULT_INVERSE,
    handleSetThemeColors,
    HIGH_LUMINANCE_BORDER,
    HIGH_LUMINANCE_INVERSE,
} from "utilities/theme";
import { useSettings } from "./useSettings";
import { core_palette } from "utilities/palette";

const buttonRadius = {
    square: 1,
    soft: 12,
    round: 9999,
};

const blockRadius = {
    square: 1,
    soft: 12,
    round: 24,
};

function generateTheme(settings: Settings): {
    mode: DarkMode;
    backgroundColor: string;
    backgroundType: BackgroundType;
    theme_primary: string;
    theme_secondary: string;
    theme_tertiary: string;
    theme_inverse: string;
    theme_border: string;
    theme_backdropFilter: string;
} {
    const backgroundType = settings.background_type || BackgroundType.minimal;
    const mode = DarkMode.light; // theme mode won't change based on system mode
    let theme_primary =
        settings.primary || chroma(core_palette.primary[mode]).hex();
    let theme_secondary =
        settings.secondary || chroma(core_palette.secondary[mode]).hex();
    let theme_tertiary =
        settings.tertiary || chroma(core_palette.tertiary[mode]).hex();
    let theme_inverse = DEFAULT_INVERSE;
    let theme_border = DEFAULT_BORDER;
    let backgroundColor = settings.background_color || DEFAULT_BACKGROUND;
    let theme_backdropFilter = "unset";

    if (backgroundType === BackgroundType.minimal) {
        const color = chroma(backgroundColor);
        const luminance = color.luminance();
        if (luminance >= 0.3) {
            theme_inverse = HIGH_LUMINANCE_INVERSE;
            theme_border = HIGH_LUMINANCE_BORDER;
        }
    }

    return {
        mode,
        backgroundColor,
        backgroundType,
        theme_primary,
        theme_secondary,
        theme_tertiary,
        theme_inverse,
        theme_border,
        theme_backdropFilter,
    };
}

export function useTheme(): Theme {
    const settings = useSettings();
    const shape = settings.shape;
    const {
        mode,
        backgroundColor,
        backgroundType,
        theme_primary,
        theme_secondary,
        theme_tertiary,
        theme_inverse,
        theme_border,
        theme_backdropFilter,
    } = generateTheme(settings);

    useEffect(() => {
        handleSetThemeColors(CSSVariableName.background, backgroundColor);
        handleSetThemeColors(CSSVariableName.primary, theme_primary);
        handleSetThemeColors(CSSVariableName.secondary, theme_secondary);
        handleSetThemeColors(CSSVariableName.tertiary, theme_tertiary);
        handleSetThemeColors(CSSVariableName.inverse, theme_inverse);
        handleSetThemeColors(CSSVariableName.border, theme_border);
        handleSetThemeColors(
            CSSVariableName.backdropFilter,
            theme_backdropFilter,
        );
    }, [
        settings.name,
        settings.background_type,
        settings.background_preset_name,
        backgroundColor,
        backgroundType,
        theme_border,
        theme_inverse,
        theme_primary,
        theme_secondary,
        theme_tertiary,
    ]);

    const theme = {
        mode,
        dark_mode: mode === DarkMode.dark,
        button_radius: buttonRadius[shape],
        block_radius: blockRadius[shape],
        quaternary: core_palette.quaternary[mode], // not part of theme
        quinary: core_palette.quinary[mode], // not part of theme
        shape,

        //THEME3
        background_type: backgroundType,
        background_color: backgroundColor,
        primary: theme_primary, // used as text color
        secondary: theme_secondary, // used as secondary text color
        tertiary: theme_tertiary, // used as a secondary background color
        border_color: theme_border,
        inverse: theme_inverse,
        background_preset_name: settings?.background_preset_name,
    };

    return theme;
}
