import { useSelector } from "react-redux";
import { selectSettings } from "store/profileSlice";
import { selectNewSettings } from "store/settingsEditor";
import { Settings } from "types";
import _ from "lodash";

export function useSettings(): Settings {
    const initialSettings = useSelector(selectSettings);
    const newSettings = useSelector(selectNewSettings);
    const settings = _.isEmpty(newSettings) ? initialSettings : newSettings;

    return settings;
}
